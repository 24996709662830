
    import axios from 'axios';
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class EconomyCryptocurrencies extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private cryptos: {[key: string]: any }      = {};

        private readonly errors: any[]              = [];

        private readonly bitcoinPriceGraph = {
            // tslint:disable
            traces: [
                {
                    x:          ['2017-01-17', '2017-01-18', '2017-01-19'],
                    open:       [100, 120, 130],
                    high:       [100, 120, 130],
                    low:        [100, 120, 130],
                    close:      [100, 120, 130],

                    type:       'ohlc',
                    xaxis:      'x',
                    yaxis:      'y',

                    increasing: {line: {color: 'hsl(80, 100%, 30%)', width: 1}},
                    decreasing: {line: {color: 'hsl(30, 100%, 25%)', width: 1}}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'High',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(120, 100%, 35%)", width: 1}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Low',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(0, 100%, 20%)", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly bitcoinLongTermGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Price',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(180, 100%, 35%)", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly ethereumPriceGraph = {
            // tslint:disable
            traces: [
                {
                    x:          ['2017-01-17', '2017-01-18', '2017-01-19'],
                    open:       [100, 120, 130],
                    high:       [100, 120, 130],
                    low:        [100, 120, 130],
                    close:      [100, 120, 130],

                    type:       'ohlc',
                    xaxis:      'x',
                    yaxis:      'y',

                    increasing: {line: {color: 'hsl(80, 100%, 30%)', width: 1}},
                    decreasing: {line: {color: 'hsl(30, 100%, 25%)', width: 1}}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'High',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(120, 100%, 35%)", width: 1}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Low',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(0, 100%, 20%)", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly ethereumLongTermGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Price',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(180, 100%, 35%)", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly ripplePriceGraph = {
            // tslint:disable
            traces: [
                {
                    x:          ['2017-01-17', '2017-01-18', '2017-01-19'],
                    open:       [100, 120, 130],
                    high:       [100, 120, 130],
                    low:        [100, 120, 130],
                    close:      [100, 120, 130],

                    type:       'ohlc',
                    xaxis:      'x',
                    yaxis:      'y',

                    increasing: {line: {color: 'hsl(100, 100%, 30%)', width: 1}},
                    decreasing: {line: {color: 'hsl(20, 100%, 25%)', width: 1}}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'High',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(120, 100%, 35%)", width: 1}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Low',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(0, 100%, 20%)", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly rippleLongTermGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Price',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(180, 100%, 35%)", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly basicAttentionTokenLongTermGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Price',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(180, 100%, 35%)", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly chainLinkLongTermGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Price',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "hsl(180, 100%, 35%)", width: 1}
                }
            ],
            // tslint:enable
        };

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                bitcoinIconPathAndName:
                    require("@/assets/images/icon_bitcoin.png"),

                bitcoinImagePathAndName:
                    require("@/assets/images/bitcoin.png"),

                cryptocurrencyIconPathAndName:
                    require("@/assets/images/icon_cryptocurrency.png"),

                ethereumIconPathAndName:
                    require("@/assets/images/icon_ethereum.png"),

                ethereumImagePathAndName:
                    require("@/assets/images/ethereum.png"),

                rippleIconPathAndName:
                    require("@/assets/images/icon_ripple.png"),

                rippleImagePathAndName:
                    require("@/assets/images/ripple.png"),

                stakingIconPathAndName:
                    require("@/assets/images/icon_stack_of_coins.png"),

                stakingImagePathAndName:
                    require("@/assets/images/staking.png"),

            };
        }

        private created() {
            this.requestCryptoCompareJson();
            this.requestAlphaVantageJson();
            this.requestCoinGeckoJson();
        }

        private requestCryptoCompareJson(): void {
            // Use cointmarketcap.com for acronyms
            axios.get('https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,XRP,BAT,LINK&tsyms=GBP&api_key=2f623e5d39eaad5c1c93b61734862c388556ef88758e0564583882dadc9c7889')
                .then((response: any)  => {
                    this.cryptos = response.data;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        }

        private requestAlphaVantageJson(): void {
            const alphavantageURL       = "https://www.alphavantage.co/query?";
            const functionParam         = "function=DIGITAL_CURRENCY_WEEKLY";
            const bitcoinSymbolParam    = "&symbol=BTC";
            const marketParam           = "&market=GBP";
            const apiKey                = "&apikey=Z9EFUZXYZ82F9YES";
            const bitcoinURL            = alphavantageURL + functionParam + bitcoinSymbolParam + marketParam + apiKey;
            this.downloadStatistics(bitcoinURL, this.bitcoinPriceGraph);

            const ethereumSymbolParam   = "&symbol=ETH";
            const ethereumURL           = alphavantageURL + functionParam + ethereumSymbolParam + marketParam + apiKey;
            this.downloadStatistics(ethereumURL, this.ethereumPriceGraph);

            const rippleSymbolParam     = "&symbol=XRP";
            const rippleURL             = alphavantageURL + functionParam + rippleSymbolParam + marketParam + apiKey;
            this.downloadStatistics(rippleURL, this.ripplePriceGraph);
        }

        private downloadStatistics(url: string, graph: any): void {
            axios.get(url).then((response: any) => {
                this.parseToGraph(graph, response.data["Time Series (Digital Currency Weekly)"]);
            }).catch((e) => { this.errors.push(e); });
        }

        private parseToGraph(graph: any, data: any): void {
            const ohlcIndex: number         = 0;
            const highIndex: number         = 1;
            const lowIndex: number          = 2;

            graph.traces[ohlcIndex].x       = [];
            graph.traces[ohlcIndex].open    = [];
            graph.traces[ohlcIndex].high    = [];
            graph.traces[ohlcIndex].low     = [];
            graph.traces[ohlcIndex].close   = [];

            graph.traces[highIndex].x       = [];
            graph.traces[highIndex].y       = [];

            graph.traces[lowIndex].x        = [];
            graph.traces[lowIndex].y        = [];

            Object.keys(data).forEach((key: any) => {
                const open  = data[key]["1a. open (GBP)"];
                const high  = data[key]["2a. high (GBP)"];
                const low   = data[key]["3a. low (GBP)"];
                const close = data[key]["4a. close (GBP)"];

                graph.traces[ohlcIndex].x.push(key);
                graph.traces[ohlcIndex].open.push(open);
                graph.traces[ohlcIndex].high.push(high);
                graph.traces[ohlcIndex].low.push(low);
                graph.traces[ohlcIndex].close.push(close);

                graph.traces[highIndex].x.push(key);
                graph.traces[highIndex].y.push(high);

                graph.traces[lowIndex].x.push(key);
                graph.traces[lowIndex].y.push(low);
            });
        }

        private requestCoinGeckoJson(): void {
            const coinGeckoURL  = "https://api.coingecko.com/api/v3/coins";
            const bitcoinId     = "/bitcoin";
            const marketChart   = "/market_chart/range";
            const currency      = "?vs_currency=gbp";
            const from          = "&from=1483228800";
            const milliseconds  = 1000;
            const to            = "&to=" + Math.floor(Date.now() / milliseconds);   // Convert to seconds
            const bitcoinURL    = coinGeckoURL + bitcoinId + marketChart + currency + from + to;
            this.downloadCoinGeckoStatistics(bitcoinURL, this.bitcoinLongTermGraph);

            const ethereumId    = "/ethereum";
            const ethereumURL   = coinGeckoURL + ethereumId + marketChart + currency + from + to;
            this.downloadCoinGeckoStatistics(ethereumURL, this.ethereumLongTermGraph);

            const rippleId      = "/ripple";
            const rippleURL     = coinGeckoURL + rippleId + marketChart + currency + from + to;
            this.downloadCoinGeckoStatistics(rippleURL, this.rippleLongTermGraph);

            const batId         = "/basic-attention-token";
            const batURL        = coinGeckoURL + rippleId + marketChart + currency + from + to;
            this.downloadCoinGeckoStatistics(batURL, this.basicAttentionTokenLongTermGraph);

            const linkId        = "/chainlink";
            const linkURL       = coinGeckoURL + linkId + marketChart + currency + from + to;
            this.downloadCoinGeckoStatistics(linkURL, this.chainLinkLongTermGraph);
        }

        private downloadCoinGeckoStatistics(url: string, graph: any): void {
            axios.get(url).then((response: any) => {
                this.parseCoinGeckoDataToGraph(graph, response.data.prices);
            }).catch((e) => { this.errors.push(e); });
        }

        private parseCoinGeckoDataToGraph(graph: any, data: any): void {
            graph.traces[0].x   = [];
            graph.traces[0].y   = [];

            Object.keys(data).forEach((key: any) => {
                const dateIndex: number     = 0;
                const priceIndex: number    = 1;

                const timeStamp     = parseInt(data[key][dateIndex], 10);
                const date          = new Date(timeStamp);
                const dateString    = this.formatDate(date);
                graph.traces[0].x.push(dateString);

                const price         = parseFloat(data[key][priceIndex]);
                graph.traces[0].y.push(price);
            });
        }

        private formatDate(date: Date): string {
            const year  = date.getFullYear();
            let month   = '' + (date.getMonth() + 1);
            let day     = '' + date.getDate();

            const singleDigitThreshold = 2;
            if (month.length < singleDigitThreshold) {
                month = '0' + month;
            }
            if (day.length < singleDigitThreshold) {
                day = '0' + day;
            }

            return [year, month, day].join('-');
        }

        private bitcoinValue(): string {
            const key: string   = "BTC";
            const bitcoin: any  = this.cryptos[key];
            if (bitcoin != null) {
                return "£" + bitcoin.GBP.toFixed(2);
            } else {
                return "";
            }
        }

        private ethereumValue(): string {
            const key: string   = "ETH";
            const ethereum: any = this.cryptos[key];
            if (ethereum != null) {
                return "£" + ethereum.GBP.toFixed(2);
            } else {
                return "";
            }
        }

        private rippleValue(): string {
            const key: string   = "XRP";
            const ripple: any = this.cryptos[key];
            if (ripple != null) {
                return "£" + ripple.GBP.toFixed(2);
            } else {
                return "";
            }
        }

        private basicAttentionTokenValue(): string {
            const key: string   = "BAT";
            const basicAttentionToken: any = this.cryptos[key];
            if (basicAttentionToken != null) {
                return "£" + basicAttentionToken.GBP.toFixed(2);
            } else {
                return "";
            }
        }

        private chainlinkValue(): string {
            const key: string   = "LINK";
            const chainlink: any = this.cryptos[key];
            if (chainlink != null) {
                return "£" + chainlink.GBP.toFixed(2);
            } else {
                return "";
            }
        }

        private get createDefaultLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "£ GBP");
            // tslint:enable
        }
    }
